import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
// components
import { Box, Container, Stack, Typography } from '@mui/material';
import Logo from '../components/Logo';
import LogoText from '../components/LogoText';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

LogoOnlyLayout.propTypes = {
  children: PropTypes.node,
};

export default function LogoOnlyLayout({ children }) {
  return (
    <>
      {/* <Messenger /> */}
      <HeaderStyle>
        <LogoText />
      </HeaderStyle>
      {children}
      <Footer />
    </>
  );
}

const Footer = () => (<Box
  sx={{
    py: 5,
    textAlign: 'center',
    position: 'relative',
    bgcolor: 'background.default',
  }}
>
  <Container>
    <Logo sx={{ mb: 1, mx: 'auto' }} />

    <Stack spacing={1} my={2} alignItems={"center"} justifyContent={"center"}>
      <Typography variant="caption" component="p">
        © All rights reserved
      </Typography>
      <Typography component="p" variant="body2" >
        © {new Date().getFullYear()}. by <a href="https://jmaster.io" target='_blank' rel="noreferrer">JMaster.io</a>
      </Typography>
      {/* <a href="//www.dmca.com/Protection/Status.aspx?ID=86c6be9b-632c-409b-9123-c35c61beae0d" title="DMCA.com Protection Status" className="dmca-badge">
        <img height={20} width={100} src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-08.png?ID=86c6be9b-632c-409b-9123-c35c61beae0d" alt="DMCA.com Protection Status" />
      </a>
      <Script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js" /> */}
    </Stack>
  </Container>

</Box>);