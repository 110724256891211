// routes
import { PATH_PHAPTHOAI, PATH_RESOURCE } from '../../routes/paths';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: "menu.book",
    icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
    path: PATH_PHAPTHOAI.book,
  },
  {
    title: "menu.question",
    icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
    path: PATH_RESOURCE.questions,
  },
  {
    title: "menu.blog",
    icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
    path: PATH_RESOURCE.blogs,
  },
  {
    title: "menu.forum",
    icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
    path: PATH_RESOURCE.forums,
  },
];

export default menuConfig;
