//pages/sitemap_index.xml.js
export const EXTERNAL_DATA_URL = 'https://phapthoai.net';

function generateSiteMap() {
  return `<?xml version="1.0" encoding="UTF-8"?>
  <sitemapindex xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
    <sitemap>
      <loc>${EXTERNAL_DATA_URL}/sitemap-tag.xml</loc>
    </sitemap>
    <sitemap>
      <loc>${EXTERNAL_DATA_URL}/sitemap-book.xml</loc>
    </sitemap>
    <sitemap>
      <loc>${EXTERNAL_DATA_URL}/sitemap-blog.xml</loc>
    </sitemap>
    <sitemap>
      <loc>${EXTERNAL_DATA_URL}/sitemap-forum.xml</loc>
    </sitemap>
    <sitemap>
      <loc>${EXTERNAL_DATA_URL}/sitemap-question.xml</loc>
    </sitemap>
  </sitemapindex>`;
}

function SiteMap() {
  // getServerSideProps will do the heavy lifting
}

export async function getServerSideProps({ res }) {
  // We generate the XML sitemap with the posts data
  const sitemap = generateSiteMap();

  res.setHeader('Content-Type', 'text/xml');
  // we send the XML to the browser
  res.write(sitemap);
  res.end();

  return {
    props: {},
  };
}

export default SiteMap;