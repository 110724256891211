// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
  register: '/register',
  profile: '/auth/profile',
  setting: "/auth/setting",
  dashboard: "/auth/dashboard"
};

export const PATH_PAGE = {
  home: '/',
  about: '/about-us',
  terms: '/terms',
  privacy: '/privacy',
};

export const PATH_PHAPTHOAI = {
  book: '/books',
  vegan: '/vegan',
  tour: '/tour',
};

export const PATH_RESOURCE = {
  blogs: '/blogs',
  questions: '/questions',
  forums: '/forums',
};