import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { Box, Link } from '@mui/material';
import Image from 'next/image';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx }, ref) => {

  const logo = (
    <Box ref={ref} sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}>
      <Image
        alt="Phapthoai.net"
        src={`/logo/logo.png`}
        width={40}
        height={40}
      />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <Link component={NextLink} href="/">{logo}</Link>;
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
