import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// next
import Head from 'next/head';
// @mui
import { Box } from '@mui/material';
import { useRouter } from 'next/router';
import { EXTERNAL_DATA_URL } from '../pages/sitemap.xml';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', description = "", imagePath, meta, ...other }, ref) => {
  const { asPath } = useRouter();
  const pathname = `${EXTERNAL_DATA_URL}${asPath}`;

  return (
    <>
      <Head>
        <title>{`PhapThoai.net | ${title}`}</title>
        <meta name="description" content={description} />

        <meta property="og:type" content="article" />
        <meta property="og:image" content={imagePath || `${EXTERNAL_DATA_URL}/image/banner.jpg`} />
        <meta property="og:url" content={pathname} />

        <meta property="og:description" content={description} />
        <meta property="og:site_name" content="phapthoai.net" />
        <meta property="fb:app_id" content="632228391747595" />

        {meta}
      </Head >

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  imagePath: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
