// next
import NextLink from 'next/link';
// @mui
import { Container, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// routes
import { PATH_PAGE, PATH_PHAPTHOAI, PATH_RESOURCE } from '../../routes/paths';
// components
import Image from 'next/image';
import SocialsButton from '../../components/SocialsButton';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  let { translate } = useLocales();
  let { themeMode } = useSettings();

  const LINKS = [
    {
      headline: translate("menu.home"),
      children: [
        { name: translate("menu.book"), href: PATH_PHAPTHOAI.book },
        { name: translate("menu.vegan"), href: "#" },
        { name: translate("menu.tour"), href: "#" },
      ],
    },
    {
      headline: translate("menu.forum"),
      children: [
        { name: translate("menu.question"), href: PATH_RESOURCE.questions },
        { name: translate("menu.blog"), href: PATH_RESOURCE.blogs },
        { name: translate("menu.forum"), href: PATH_RESOURCE.forums },
      ],
    },
    {
      headline: translate("menu.terms"),
      children: [
        { name: translate("menu.termofuse"), href: PATH_PAGE.terms },
        { name: translate("menu.privacy"), href: PATH_PAGE.privacy },
        { name: translate("menu.aboutus"), href: PATH_PAGE.about },
      ],
    },
  ];


  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 6, mb: 1 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} md={5}>
            <Image
              alt="phapthoai.net"
              src={themeMode == 'light' ? `/logo/logo.png` : '/logo/logo.png'}
              width={40}
              height={40}
              style={{ marginBottom: 2 }}
            />

            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              {translate("index.description")}
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 2, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5 }} links={{
                facebook: 'https://www.facebook.com/phapthoainet',
                youtube: "https://www.youtube.com/@phapthoainet",
                tictok: "https://www.tiktok.com/@phapthoainet"
              }} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-around"
            >
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => (
                    <Link key={link.name} href={link.href} color="inherit" variant="body2" component={NextLink}>
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Stack spacing={1} my={2} alignItems={"center"} justifyContent={"center"}>
          <Typography component="p" variant="body2" >
            ©{new Date().getFullYear()}. by <Link href="https://jmaster.io" target='_blank' rel="noreferrer" color="inherit" variant="body2" >JMaster.io</Link>
          </Typography>
          {/* <a href="//www.dmca.com/Protection/Status.aspx?ID=86c6be9b-632c-409b-9123-c35c61beae0d" title="DMCA.com Protection Status" className="dmca-badge">
            <img height={20} width={100} src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-08.png?ID=86c6be9b-632c-409b-9123-c35c61beae0d" alt="DMCA.com Protection Status" />
          </a>
          <Script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js" /> */}
        </Stack>
      </Container>
    </RootStyle>
  );
}