import PropTypes from 'prop-types';
// next
// @mui
import { Box, Stack, styled } from '@mui/material';
// components
//
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(13),
  },
}));

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function MainLayout({ children }) {

  return (
    <>
      {/* <Messenger /> */}
      <Stack>
        <MainHeader />
        <RootStyle>
          {children}
        </RootStyle>
        <Box sx={{ flexGrow: 1 }} />
        <MainFooter />
      </Stack>
    </>
  );
}
